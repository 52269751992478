<template>
<div>
  <v-card-title>
    Subsidence
  </v-card-title>
  <v-subheader>
    Coming soon
  </v-subheader>
  <v-sheet class="pa-5">

  </v-sheet>
</div>
</template>

<script>
export default {

};
</script>
